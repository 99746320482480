



















import FormattedText from '@/components/typography/FormattedText.vue'
import { Vue, Component } from 'vue-property-decorator'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import GridNav from '@/components/grid/GridNav.vue'
import GridNavItem from '@/components/grid/GridNavItem.vue'
import Paging from '@/components/actions/Paging.vue'
import EventCard from '@/components/collections/events/EventCard.vue'

@Component({
  components: {
    EventCard,
    Paging,
    GridNavItem,
    GridNav,
    FormattedText
  }
})

export default class MasterCategories extends Vue {
  private categories: CategoryModel[] = [
    {
      CategoryId: '675b776c-8320-4786-ba9e-01bd48ecf34e',
      Name: 'Community',
      Icon: 'groups',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '5b0f8784-bd7a-4d18-b990-02f0c087c6d4',
      Name: 'Arts & Recreation',
      Icon: 'palette',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '0d36d393-2e44-45a2-bdf0-03f1b97152c7',
      Name: 'Transport & Delivery',
      Icon: 'local_shipping',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'ee414826-8aee-4481-a5a0-189ac3d0b604',
      Name: 'Automotive',
      Icon: 'directions_car',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'a93580b7-5222-4a05-9530-1fe30d5f6135',
      Name: 'Health and Fitness',
      Icon: 'fitness_center',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '879063b0-ccd9-45f7-9f44-235dbed1e368',
      Name: 'Manufacturing and Wholesale',
      Icon: 'inventory_2',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'f0f21278-dced-4e12-8b08-256f5eb62dde',
      Name: 'IT & Communications',
      Icon: 'devices',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '16e8bed8-83c9-4b95-b9af-36962f1566e4',
      Name: 'Shopping & Retail',
      Icon: 'storefront',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '2840a048-fc18-44bb-b28b-3e3297ed7203',
      Name: 'Real Estate',
      Icon: 'real_estate_agent',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '6d4b7e4b-0409-4caf-ba4e-4c392ad1284a',
      Name: 'Trades and Services',
      Icon: 'handyman',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'c8693777-727a-4d79-9286-4e392dfd03f8',
      Name: 'Fashion & Beauty',
      Icon: 'face',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'df7771d5-74e9-472a-aaee-5bd3c103e5f1',
      Name: 'Legal',
      Icon: 'gavel',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '0131d776-e825-45f4-a679-6f03fe80055e',
      Name: 'Entertainment & Leisure',
      Icon: 'theater_comedy',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '23a7eb4f-006b-499b-a6cf-6ff81599df88',
      Name: 'Marine',
      Icon: 'directions_boat',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '2a92c59e-73c1-4df8-b926-73edeaad8477',
      Name: 'Home & Garden',
      Icon: 'home',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '0917479a-50bc-4d57-bf13-802136bd458d',
      Name: 'Education & Training',
      Icon: 'school',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '20eb53fb-ef9e-4963-a146-8931d030948b',
      Name: 'Medical',
      Icon: 'medical_services',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'a82b6392-b4fb-4c0a-992a-9f0f9a74f2fa',
      Name: 'Business and Administration',
      Icon: 'work',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '0eee2410-4e62-4446-8a0e-9fca571d6291',
      Name: 'Utilities',
      Icon: 'bolt',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '6b67099e-6236-4113-b22c-a27a8f5e13e2',
      Name: 'Functions & Events',
      Icon: 'nightlife',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'c9dfdfbe-03ef-4ac5-99b4-a9afc23ff120',
      Name: 'Pets',
      Icon: 'pets',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'f7db95f4-20d3-48c9-bb47-b63328545c37',
      Name: 'Real Estate',
      Icon: 'home',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '092217de-6c9c-441f-ace1-c52b73f128b4',
      Name: 'Marketing & Design',
      Icon: 'send',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '706e8f0f-80c0-46d7-8ed8-dc580fed2aa2',
      Name: 'Food & Beverage',
      Icon: 'restaurant',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: 'afdf1e83-ab7c-46be-bbb7-e63ec62131b6',
      Name: 'Arts & Recreation',
      Icon: 'palette',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '5308f302-fdba-4205-9b06-eba29cf91692',
      Name: 'Finance',
      Icon: 'savings',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '920a91f3-68e4-4dc0-ac7a-f84178a26bb9',
      Name: 'Agriculture & Farming',
      Icon: 'agriculture',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '94aa2f88-431a-416c-9a00-f8e2d037e70a',
      Name: 'Insurance',
      Icon: 'account_balance',
      Parent: null,
      Eyebrow: ''
    },
    {
      CategoryId: '11491b18-488d-495a-b80f-f94a37b1aaca',
      Name: 'Travel and Accommodation',
      Icon: 'flight_takeoff',
      Parent: null,
      Eyebrow: ''
    }
  ]
}

